* {
  margin: 0;
  padding: 0;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  scroll-behavior: smooth;
}

html {
  font-size: 16px;
}

.App {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto auto auto;
  grid-template-columns: auto;
  grid-template-areas: "nav" "article" "footer" "socialMedia" "copyright";
}

@font-face {
  font-family: Akzidenz-Grotesk-Regular;
  src: url(https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap);
  font-display: auto;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.disableOverflow {
  overflow: hidden;
}

@media only screen and (min-width: 992px) {
  html {
    font-size: 1em;
  }
}
